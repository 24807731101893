import React from 'react';

const Section = ({ id, contentSlot }) => (
  <section id={id} className="py-20">
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
        {contentSlot}
    </div>
  </section>
);

export default Section;