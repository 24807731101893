import React from 'react';
import Layout from '../components/layout/Layout';
import Section from '../components/Section';
import Hero from '../components/Hero';
import { Helmet } from 'react-helmet';
import JDM from "../images/JDM.png";
import TAH from "../images/TAH.png";
import coverImg from '../images/leadership.jpg';
import ShareImg from "../images/share.png";

export default () => (
  <Layout>
    <Hero
      coverImage={ coverImg }
      primaryContent={
        <span>Leadership Team</span>
      }
    />
    <Section
      contentSlot={
        <div>
          <h1 class="text-4xl">Our Leadership</h1>
          <div class="text-gray-700 body-font">
            <div class="container px-5 py-12 mx-auto">
              <div class="flex flex-wrap -m-4 items-center sm:items-start">
                <div class="p-4 md:w-1/5 w-full">
                  <img src={JDM} alt="Joseph D. Meddings, CPA, CTP" />
                </div>
                <div class="p-4 md:w-4/5 w-full">
                  <h2 class="text-xl font-medium title-font text-gray-900">
                    Joseph D. Meddings, CPA (inactive), CTP
                  </h2>
                  <p class="mb-4">
                    Joe Meddings has a wide variety of business experience that
                    spans industry boundaries. He has been CEO, COO and CFO in
                    the manufacturing, service and high tech sectors, including
                    both private and public companies. He has successfully led
                    organizations into strategic area such as acquisitions,
                    foreign investments, foreign financing, venture capital and
                    private equity financing and public offerings. With Hollis
                    Meddings Group, he has led multiple entities through various
                    aspects of turnarounds, restructurings, divestitures and
                    acquisitions. Mr. Meddings has an undergraduate degree in
                    Accounting and Math from Milligan College, as well as a
                    graduate degree in Operations/Logistics Management and
                    Corporate Finance from The Ohio State University. Joe is a
                    member of the American Institute of Certified Public
                    Accountants, the Association of Certified Turnaround
                    Professionals, The Association of Insolvency and
                    Restructuring Advisors, The Bankruptcy Institute, The
                    Turnaround Management Association, Risk Management
                    Association and Commercial Finance Association.
                  </p>
                  <a class="inline-flex mx-auto text-white bg-red-600 border-0 py-2 px-8 focus:outline-none rounded text-lg" href="mailto:jmeddings@hollismeddings.com">Email Joe</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-gray-700 body-font">
            <div class="container px-5 py-12 mx-auto">
              <div class="flex flex-wrap -m-4 items-center sm:items-start">
                <div class="p-4 md:w-1/5 w-full">
                  <img src={TAH} alt="Thomas A. Hollis" />
                </div>
                <div class="p-4 md:w-4/5 w-full">
                  <h2 class="text-xl font-medium title-font text-gray-900">
                    Thomas A. Hollis
                  </h2>
                  <p class="mb-4">
                    Tom Hollis has a strong organizational and financial
                    background. Spending the first half of his career in
                    banking, his job responsibilities included those of Loan
                    Officer, Department Head in Asset Based Lending, Department
                    Head of Controlled and Classified Loans, and Senior Loan
                    Officer for all Commercial Lending. Mr. Hollis also served
                    as Treasurer of a $300 million metal refining and trading
                    company, as well as CFO of a metal trading company. Tom has
                    an undergraduate degree in Economics and Accounting from
                    Merrimack College, as well as a graduate degree in Finance
                    from The University of Massachusetts in Amherst. He is a
                    member of the Turnaround Management Association, Risk
                    Management Association and Commercial Finance Association.
                  </p>
                  <a class="inline-flex text-white bg-red-600 border-0 py-2 px-8 focus:outline-none rounded" href="mailto:thollis@hollismeddings.com">Email Tom</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Our Leadership | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
);